<template>
    <kurcc-data-table-page-template :data-table-title="getDataTableTitle"
                                    :default-item="defaultItem"
                                    :delete-dialog-title="$vuetify.lang.t('$vuetify.pages.site.deleteDialogText')"
                                    :get-all-action-additional-params="prepareAdditionalParams"
                                    :headers="headers"
                                    :show-delete-action="false"
                                    :show-toolbar-button="false"
                                    :update-dialog-title="$vuetify.lang.t('$vuetify.pages.site.editBranch')"
                                    actions-suffix="Branch"
                                    show-select>
        <template v-slot:dialog="{item}">
            <kurcc-branch-dialog :item="item"/>
        </template>
    </kurcc-data-table-page-template>
</template>

<script>
import Branch from "@/modules/sites/models/branch";

export default {
    name: "KurccSiteBranchesPage",
    components: {
        KurccDataTablePageTemplate: () => import('@/modules/app/templates/KurccDataTablePageTemplate'),
        KurccBranchDialog: () => import('@/modules/sites/components/KurccBranchDialog'),
    },
    props: ['site', 'site_id'],
    data() {
        return {
            headers: [
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.site.branchNumber'),
                    align: 'start',
                    value: 'number',
                },
                {text: this.$vuetify.lang.t('$vuetify.pages.site.branchNameEn'), value: 'brand_name_en'},
                {text: this.$vuetify.lang.t('$vuetify.pages.site.branchNameAr'), value: 'brand_name_ar'},
                {text: this.$vuetify.lang.t('$vuetify.pages.site.address'), value: 'full_address', sortable: false},
                {text: this.$vuetify.lang.t('$vuetify.pages.site.managerName'), value: 'manager_name'},
                {text: this.$vuetify.lang.t('$vuetify.pages.site.managerPhoneNumber'), value: 'manager_phone_number'},
                {
                    text: this.$vuetify.lang.t('$vuetify.pages.inspections.dataTableHeaders.grade'),
                    value: 'last_done_inspection.grade'
                },
                {text: this.$vuetify.lang.t('$vuetify.general.verified'), value: 'is_verified_human_readable'},
                {text: this.$vuetify.lang.t('$vuetify.general.createdAt'), value: 'created_at'},
                {text: this.$vuetify.lang.t('$vuetify.general.actions'), value: 'actions', sortable: false},
            ],
            defaultItem: new Branch(),
            siteData: undefined
        }
    },
    computed: {
        prepareAdditionalParams() {
            let params = []
            if (this.site_id) params.push(`site_ids=["${this.site_id}"]`)
            if (this.displaySiteUnverifiedBranches) params.push('is_verified=0')
            return params
        },
        getDataTableTitle() {
            if (this.siteData)
                return `${this.$vuetify.lang.t('$vuetify.pages.site.branchList')} (${this.$vuetify.lang.t('$vuetify.general.site')}: ${this.siteData.name})`
            else return this.$vuetify.lang.t('$vuetify.pages.site.branchList')
        },
        displaySiteUnverifiedBranches() {
            return this.$route.name === 'site-unverified-branches'
        }
    },
    methods: {
        fetchSiteData() {
            this.$store.dispatch('getSite', {id: this.site_id})
                .then(res => {
                    this.siteData = res
                })
        },
    },
    created() {
        if (this.site_id) {
            if (this.site)
                this.siteData = this.site
            else this.fetchSiteData()
        }
    }
}
</script>
